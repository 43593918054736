<template>
    <setting-layout :title="$t('pages.user.setting.creator.messages.title')">
        <v-container>
            <v-row no-gutters class="ma-0 pt-3 pb-3 align-center justify-space-between">
                <div class="text-h6" @click="showTagsInfo = !showTagsInfo">
                    {{ $t('pages.user.setting.creator.messages.direct_messages_price') }}
                    <IconInfo style="margin-bottom: -2px" />
                </div>

                <div v-if="showTagsInfo" class="mb-2 mt-1">
                    {{ $t('pages.user.setting.creator.messages.direct_message_info') }}
                </div>
            </v-row>
            <v-row>
                <text-field :placeholder="$t('pages.user.setting.creator.messages.placeholder')"
                            v-model="price"
                            v-on:keyup.enter="save"
                            :error-messages="errors.price"
                            :prepend="currency"
                >
                    <template #append>
                        <div style="opacity: 0.5">
                            {{ $t("pages.user.setting.creator.messages.price_suffix") }}
                        </div>
                    </template>
                </text-field>
            </v-row>
            <v-row justify="center" class="mt-4">
                <gradient-button block @click="save"
                                 :loading="loading">
                    {{ $t('pages.user.setting.creator.messages.save') }}
                </gradient-button>
            </v-row>

            <set-messages-price-error
                v-if="this.showSetMessagesPriceErrorDialog"
                :subscription-price="subscriptionPrice"
                @close="handleCloseSetMessagesPriceErrorDialog"
            />
        </v-container>
    </setting-layout>
</template>

<script>
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import {CURRENCY_SYMBOL} from "@/configs/constants";
import {mapActions, mapMutations} from "vuex";
import SetMessagesPriceError from "@/components/app/dialogs/SetMessagesPriceError.vue";
import IconInfo from "@/assets/images/icons/info.svg?inline";

export default {
    name: "Messages",
    data: () => ({
        price: 0,
        loading: false,
        errors: {},
        currency: CURRENCY_SYMBOL,
        showSetMessagesPriceErrorDialog: false,
        subscriptionPrice: null,
        showTagsInfo: false,
    }),
    methods: {
        ...mapActions([
            'setPriceMessage',
            'getCurrentUserData',
        ]),
        ...mapMutations([
            'showSnackBar',
        ]),
        handleCloseSetMessagesPriceErrorDialog() {
          this.showSetMessagesPriceErrorDialog = false
        },
        save() {
            this.errors = {}
            this.loading = true
            this.setPriceMessage({
                model: {
                    price: this.price > 0 ? this.price : null
                }
            }).then(() => {
                this.showSnackBar(this.$t('pages.user.setting.creator.messages.saved'))
                this.refreshData()
                this.loading = false
            }).catch((e) => {
              console.log(e.response.data)
                if (e.response.data.message === 'messages_subscription_price_mustnt_be_greater_content_subscription') {
                  this.showSetMessagesPriceErrorDialog = true
                } else {
                  this.errors = e.response.data.errors
                }

                this.loading = false
            })
        },
        refreshData() {
            this.getCurrentUserData().then((res) => {
                this.price = res.data.directMessagePrice
                this.subscriptionPrice = res.data.subscribePrice
            })
        },
    },
    mounted() {
        this.refreshData()
    },
    components: {SetMessagesPriceError, SettingLayout, TextField, GradientButton, IconInfo}
}
</script>

<style scoped>

</style>
